import Vue from "vue";
import Vuetify, {
  VApp,
  VContent,
  VContainer,
  VRow,
  VCol,
  VCheckbox,
  VMenu,
  VDivider,
  VBtn,
  VIcon,
  VScrollYTransition,
  VPagination,
  VProgressLinear,
  VTextField,
  VCard,
  VAlert,
  VDataTable
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VContent,
    VContainer,
    VRow,
    VCol,
    VCheckbox,
    VMenu,
    VDivider,
    VBtn,
    VIcon,
    VScrollYTransition,
    VPagination,
    VProgressLinear,
    VTextField,
    VCard,
    VAlert,
    VDataTable
  }
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#ee44aa",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
