<template>
  <div ref="autocomplete">
    <div class="select-wrapper v-btn--target" @click="toggleVisibility">
      {{ selectedName }}
    </div>
    <base-float-container
      v-if="show"
      :style="{ top, left }"
      style="min-width: 15vw; max-width:30vw;"
      class="pa-2"
      v-click-outside="onClickOutside"
    >
      <div>
        <v-progress-linear
          indeterminate
          absolute
          top
          v-show="loading"
        ></v-progress-linear>
        <v-text-field
          @input="onSearchInput"
          :value="searchFilter"
        ></v-text-field>

        <v-row no-gutters>
          <v-col
            cols="12"
            v-for="item in result.content"
            :key="`${item[id]} - ${item[description]}`"
            @click="onClickCheckbox(item)"
          >
            <v-row align="center" no-gutters>
              <v-col cols="1">
                <v-icon class="pr-2">{{
                  selected == item[id]
                    ? "mdi-checkbox-marked-outline"
                    : "mdi-checkbox-blank-outline"
                }}</v-icon>
              </v-col>
              <v-col>
                {{ `${item[id]} - ${item[description]}` }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="11" class="pb-0">
            <v-pagination
              color="primary"
              :value="result.number + 1"
              @input="onPaginationInput"
              :length="result.totalPages"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </base-float-container>
  </div>
</template>

<script>
import BaseFloatContainer from "./BaseFloatContainer";
import vClickOutside from "v-click-outside";
import fetcher from "../helpers/fetcher";
import debounce from "lodash.debounce";
export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    "base-float-container": BaseFloatContainer
  },

  props: {
    path: {
      type: String,
      default: () => ""
    },
    cdCliente: {
      type: Number,
      default: () => 49
    },
    token: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    id: {
      type: String,
      default: () => ""
    },
    method: {
      type: String,
      default: () => "get"
    },
    value: {
      type: Number,
      default: () => null
    },
    parentSize: {
      type: Number,
      default: () => 0
    }
  },

  data() {
    return {
      searchFilter: "",
      page: 0,
      size: 12,
      show: false,
      left: 0,
      top: 0,
      visibility: false,
      result: [],
      selected: this.value,
      selectedName: this.$i18n.t("filter.empty"),
      loading: false
    };
  },
  methods: {
    toggleVisibility() {
      this.getItems();
      this.show = !this.show;
      const element = this.$refs.autocomplete;
      const size = this.parentSize - window.innerWidth;
      const btn = element
        .querySelector(".v-btn--target")
        .getBoundingClientRect();
      this.left = `${btn.left + size}px`;
      this.top = `${btn.top + 30}px`;
    },
    onClickOutside() {
      this.show = false;
    },
    onPaginationInput(v) {
      this.page = v - 1;
    },
    getItems() {
      this.loading = true;
      if (this.method == "get") {
        let url = "";
        if (this.searchFilter != "") {
          url = `${this.path}/${this.cdCliente}/search/${this.searchFilter}?page=${this.page}&size=${this.size}`;
        } else {
          url = `${this.path}/${this.cdCliente}?page=${this.page}&size=${this.size}`;
        }
        fetcher.getItems(url, this.token).then(res => (this.result = res));
      } else {
        let data = {
          ids: "",
          page: this.page,
          size: this.size,
          search: this.searchFilter
        };
        let path = this.path.split("/");
        let url =
          "/" + path[1] + `/${this.cdCliente}/${path[2]}/filterByHierarchy`;
        fetcher
          .postItems(url, data, this.token)
          .then(res => (this.result = res));
      }
      this.loading = false;
    },
    onSearchInput: debounce(function(e) {
      this.searchFilter = e;
      this.page = 0;
      this.getItems();
    }, 1000),
    onClickCheckbox(item) {
      this.selected = item[this.id];
      this.selectedName = item[this.description];
      this.$emit("onInput", this.selected);
      this.show = false;
    }
  },
  watch: {
    page: function() {
      this.getItems();
    }
  }
};
</script>

<style>
.select-wrapper {
  position: relative;
  border-bottom: 1px solid #949494;
  color: #949494;
  height: 24px;
}
.select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #949494 transparent transparent transparent;
  position: absolute;
  top: 9px;
  right: 6px;
}
</style>
