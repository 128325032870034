<template>
  <v-card class="pa-8">
    <v-form>
      <v-row>
        <v-col cols="4">
          <v-text-field
            :label="$t('form.description')"
            v-model="dataPath.name"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label for="">{{ $t("filter.farm") }}</label>
          <PaginatedSelect
            path="/geo_layer/fazenda"
            description="cdFazenda"
            id="cdFazenda"
            :token="token"
            method="post"
            @onInput="updateFarm"
            :cdCliente="cdCliente"
            :value="dataPath.fazenda_id"
            :parent-size="parentSize"
          />
        </v-col>
        <v-col cols="4">
          <label for="">{{ $t("filter.typeEquipment") }}</label>
          <PaginatedSelect
            path="/tipo_equipamento"
            description="descTpEquipamento"
            id="cdTpEquipamento"
            :token="token"
            method="get"
            @onInput="updateTpEquipment"
            :cdCliente="cdCliente"
            :value="dataPath.tp_equipment"
            :parent-size="parentSize"
          />
        </v-col>
        <v-col cols="4">
          <label for="">{{ $t("form.from_route") }}</label>
          <div class="select-wrapper">
            <select
              :disabled="!!dataPath.id"
              v-model="dataPath.id_route_from"
              style="width: 100%;"
              @change="updateDataPath(dataPath.id_route_from, 'from')"
            >
              <option
                v-for="route in routes"
                v-bind:key="route.id"
                v-bind:value="route.id"
              >
                {{ route.name }}</option
              >
            </select>
          </div>
          <v-btn
            @click="setCoordinatesAndToggleMap(dataPath.from_route)"
            :disabled="!dataPath.id_route_from"
            class="my-2"
            right
            tile
            outlined
            color="#42A5F5"
          >
            <v-icon left class="pointer">mdi-map</v-icon
            >{{ $t("form.show_map") }}
          </v-btn>
        </v-col>
        <v-col cols="4">
          <label for="">{{ $t("form.to_route") }}</label>
          <div class="select-wrapper">
            <select
              :disabled="!!dataPath.id"
              v-model="dataPath.id_route_to"
              style="width: 100%;"
              @change="updateDataPath(dataPath.id_route_to, 'to')"
            >
              <option
                v-for="route in routes"
                v-bind:key="route.id"
                v-bind:value="route.id"
              >
                {{ route.name }}</option
              >
            </select>
          </div>
          <v-btn
            @click="setCoordinatesAndToggleMap(dataPath.to_route)"
            :disabled="!dataPath.id_route_to"
            class="my-2"
            right
            tile
            outlined
            color="#42A5F5"
          >
            <v-icon left class="pointer">mdi-map</v-icon
            >{{ $t("form.show_map") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-alert
            v-show="showFarmAlert"
            type="error"
            dismissible
            border="left"
          >
            {{ $t("form.warn") }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
      <v-btn
        :loading="loading"
        color="#2d9437"
        dark
        @click="submit"
        :disabled="disableSave"
      >
        {{ $t("form.save") }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import fetcher from "../helpers/fetcher";
import PaginatedSelect from "./PaginatedSelect";
export default {
  name: "RoutesForm",
  components: {
    PaginatedSelect
  },
  props: {
    path: {
      type: Object,
      default: function() {
        return {
          id: null,
          name: "",
          id_route_to: 0,
          id_route_from: 0,
          tp_equipment: 0,
          fazenda_id: 0,
          from_route: {},
          to_route: {}
        };
      }
    },
    token: {
      type: String
    },
    cdCliente: {
      type: Number,
      default: () => 49
    },
    usedFarms: {
      type: Array,
      default: () => []
    },
    parentSize: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      routes: [],
      times: [{}],
      loading: false,
      disableSave: false,
      showFarmAlert: false
    };
  },
  computed: {
    intRanges() {
      return parseInt(this.totalRanges);
    },
    dataPath() {
      return this.path;
    }
  },
  methods: {
    async submit() {
      let data = this.dataPath;

      //so faz a verificacao caso esteja editando
      if (
        this.dataPath.id == null &&
        this.usedFarms.includes(this.dataPath.fazenda_id)
      ) {
        this.disableSave = true;
        this.showFarmAlert = true;
        throw new Error("Ja existe uma rota para esta fazenda");
      }
      let response;
      if (this.dataPath.id == null) {
        await fetcher
          .post("/paths", data, this.token)
          .then(res => (response = res.data));

        await fetcher
          .get("/paths/" + response.id, this.token)
          .then(res => this.$emit("path-saved", res));
      } else {
        await fetcher
          .put("/paths/" + this.path.id, data, this.token)
          .then(res => (response = res.data));

        this.$emit("path-updated", data);
      }
    },
    setCoordinatesAndToggleMap(path) {
      const aux = {};

      this.$set(aux, "from", path.ref[0].split(","));
      this.$set(aux, "to", path.ref[1].split(","));
      this.$set(aux, "mapRoute", path.data.points.coordinates);

      this.$emit("showMap", aux);
    },
    updateDataPath(value, key) {
      if (this.usedFarms.includes(value)) {
        this.disableSave = true;
        this.showFarmAlert = true;
        throw new Error("Ja existe uma rota para esta fazenda");
      }
      this.disableSave = false;
      this.showFarmAlert = false;
      let val = this.routes.filter(route => route.id == value);
      if (key == "from") {
        this.dataPath.from_route = val[0];
      } else {
        this.dataPath.to_route = val[0];
      }
    },
    getRoutes() {
      fetcher
        .get(
          `/routes/full/?fazenda_id=${this.dataPath.fazenda_id}&tp_equipment=${this.dataPath.tp_equipment}`,
          this.token
        )
        .then(res => (this.routes = res));
    },
    updateFarm(v) {
      this.dataPath.fazenda_id = v;
    },
    updateTpEquipment(v) {
      this.dataPath.tp_equipment = v;
    }
  },
  mounted() {
    this.getRoutes();
  },
  watch: {
    path: {
      handler: function() {
        this.getRoutes();
      },
      deep: true
    }
  }
};
</script>

<style>
.select-wrapper {
  position: relative;
  border-bottom: 1px solid #949494;
  color: #949494;
}
.select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #949494 transparent transparent transparent;
  position: absolute;
  top: 9px;
  right: 6px;
}
</style>
