<template>
  <l-map
    :zoom="zoom"
    :center="center"
    ref="map"
    style="width: 500px; height: 500px;"
  >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

    <l-marker :lat-lng="markerFrom">
      <l-icon :icon-size="iconSize" :icon-anchor="iconAnchor"></l-icon>
    </l-marker>

    <l-marker :lat-lng="markerTo">
      <l-icon :icon-size="iconSize" :icon-anchor="iconAnchor"></l-icon>
    </l-marker>
    <l-geo-json :geojson="routeGeoJson" :options-style="styleFunction" />
  </l-map>
</template>

<script>
import L from "leaflet";
import { LMap, LMarker, LTileLayer, LIcon, LGeoJson } from "vue2-leaflet";
export default {
  name: "Map",
  props: {
    from: {
      type: Array,
      default: function() {
        return [0, 0];
      }
    },
    to: {
      type: Array,
      default: function() {
        return [0, 0];
      }
    },
    route: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      zoom: 7,
      markerFrom: L.latLng(this.from[0], this.from[1]),
      markerTo: L.latLng(this.to[0], this.to[1]),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      center: [0, 0],
      routeGeoJson: null
    };
  },
  computed: {
    styleFunction() {
      return {
        weight: 2,
        opacity: 0.7,
        color: "#0D47A1"
      };
    }
  },
  methods: {
    calculateCenter() {
      /*
      implementado com base em https://gist.github.com/tlhunter/0ea604b77775b3e7d7d25ea0f70a23eb
      */
      const coordinatesArray = [
        {
          latitude: this.from[0],
          longitude: this.from[1]
        },
        {
          latitude: this.to[0],
          longitude: this.to[1]
        }
      ];

      let x = 0.0;
      let y = 0.0;
      let z = 0.0;

      for (let coord of coordinatesArray) {
        let latitude = (coord.latitude * Math.PI) / 180;
        let longitude = (coord.longitude * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      }

      let total = 2;

      x = x / total;
      y = y / total;
      z = z / total;
      let centralLongitude = Math.atan2(y, x);
      let centralSquareRoot = Math.sqrt(x * x + y * y);
      let centralLatitude = Math.atan2(z, centralSquareRoot);

      this.center[0] = (centralLatitude * 180) / Math.PI;
      this.center[1] = (centralLongitude * 180) / Math.PI;
    },
    convertRouteToInt() {
      let aux = [];
      for (const r of this.route) {
        aux.push([parseFloat(r[0]), parseFloat(r[1])]);
      }
      return aux;
    },
    buildGeoJson() {
      this.routeGeoJson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: this.convertRouteToInt()
            }
          }
        ]
      };
    }
  },
  beforeCreate() {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  },
  created() {
    this.calculateCenter();
    this.buildGeoJson();
  },
  components: {
    "l-map": LMap,
    "l-marker": LMarker,
    "l-tile-layer": LTileLayer,
    "l-icon": LIcon,
    "l-geo-json": LGeoJson
  }
};
</script>

<style>
@import url("~leaflet/dist/leaflet.css");
</style>
