<template>
  <v-card class="pa-8">
    <v-form>
      <v-row>
        <wc-solinftec-filters
          :filters="filters"
          :cd-cliente="cdCliente"
          filter-type="BI"
          :token="token"
          :locale="locale"
          :small="small"
          @onInput="onInput"
          color="#ffffff"
          :parent-size="parentSize"
          filter-hierarchy="cdUnidade,null"
        ></wc-solinftec-filters>
      </v-row>
      <v-col cols="4">
        <v-btn
          dark
          class="blue darken-3"
          :loading="downloading"
          @click="download"
        >
          {{ $t("common.export") }}
        </v-btn>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: {
    parentSize: {
      type: Number,
      default: () => 0
    },
    token: {
      type: String
    },
    cdCliente: {
      type: Number,
      default: () => 49
    }
  },

  data() {
    return {
      selected: [],
      small: true,
      downloading: false,
      locale: this.$i18n.locale,
      filters: JSON.stringify([
        {
          identifier: "cdFazenda",
          id: "cdFazenda",
          description: "cdFazenda",
          labelName: "filters.farm",
          path: "/geo_layer/fazenda"
        },
        {
          identifier: "cdTpEquipamento",
          id: "cdTpEquipamento",
          description: "descTpEquipamento",
          labelName: "filters.equipmentType",
          path: "/tipo_equipamento"
        }
      ])
    };
  },

  methods: {
    onInput(value) {
      let val = value.detail[0];
      let key = Object.keys(val)[0];
      this.$set(this.selected, key, val[key]);
    },
    updateFarm(v) {
      this.fazenda_id = v;
    },
    updateTpEquipment(v) {
      this.tp_equipment = v;
    },
    download() {
      let self = this;
      self.downloading = true;

      let url = new URL(`${process.env.VUE_APP_API_ROUTES}/export`);

      let params = new URLSearchParams({
        fazenda_id: this.selected.cdFazenda,
        tp_equipment: this.selected.cdTpEquipamento
      });

      if (!this.selected.cdFazenda || this.selected.cdFazenda == "") {
        params.delete("fazenda_id");
      }

      if (
        !this.selected.cdTpEquipamento ||
        this.selected.cdTpEquipamento == ""
      ) {
        params.delete("tp_equipment");
      }

      url.search = params;

      let config = {
        headers: {
          "x-auth-token": this.token
        },
        responseType: "blob"
      };

      axios
        .get(url, config)
        .then(res => {
          const url = window.URL.createObjectURL(res.data);
          const el = document.createElement("a");
          el.setAttribute("href", url);
          el.setAttribute("download", "export.zip");
          el.style.display = "none";
          document.body.appendChild(el);
          el.click();
          window.URL.revokeObjectURL(el.href);
          document.body.removeChild(el);
          self.downloading = false;
        })
        .catch(err => {
          throw new Error("erro ao realizar requisição", err);
        });

      this.$emit("onCloseDownloadModal");
    }
  },

  mounted() {
    this.selected = [];
  }
};
</script>

<style></style>
