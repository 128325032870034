// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css);"]);
// Module
exports.push([module.id, "*{-webkit-box-sizing:border-box;box-sizing:border-box}.v-data-table{font-size:1.5em!important}.content{min-height:calc(100vh - 49px);position:relative}.right-aligned{position:absolute;right:16px;bottom:16px}.fake-overlay{position:absolute;top:0;height:100vh;width:100vw;z-index:15;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:rgba(0,0,0,.4)}.dialog{max-width:900px}.wrapper-fade-enter-active,.wrapper-fade-leave-active{-webkit-transition:opacity .3s;transition:opacity .3s}.wrapper-fade-enter,.wrapper-fade-leave-to{opacity:0}.pointer{cursor:pointer}.floating-modal{position:absolute;z-index:5}", ""]);
// Exports
module.exports = exports;
