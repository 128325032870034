<template>
  <div class="base-float-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$root.$el.appendChild(this.$el);
  }
};
</script>

<style lang="scss">
.base-float-container {
  position: absolute;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 5px 5px 13px -3px;
}
</style>
