<template>
  <v-app>
    <v-content ref="appRoot" class="content">
      <div class="ma-3 d-flex flex-row-reverse">
        <v-btn
          dark
          class="blue darken-3"
          @click="toggleDownloadDialog"
          ref="exportButton"
        >
          {{ $t("common.export") }}
        </v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="routes"
        show-expand
        :expanded.sync="expanded"
        :single-expand="true"
        item-key="id"
        expand-icon="mdi-pencil"
        :locale="bcpLocale"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 6px">
            <div style="max-width:900px; margin: 0 auto;">
              <RoutesForm
                :token="token"
                :path="item"
                :cdCliente="clientId"
                @path-updated="updateRowData"
                @showMap="toggleMap"
                :usedFarms="allCurrentUsedFarms"
                :parent-size="parentSize"
              />
            </div>
          </td>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn icon color="#ef5350" @click="deleteRoute(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        fab
        class="blue darken-3 right-aligned"
        dark
        large
        @click="showDialog = !showDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <transition name="wrapper-fade">
        <div
          class="fake-overlay"
          v-show="showDialog"
          @click.self="showDialog = false"
        >
          <div class="dialog">
            <RoutesForm
              @showMap="toggleMap"
              :token="token"
              @path-saved="updateTableData"
              :cdCliente="clientId"
              :usedFarms="allCurrentUsedFarms"
              :parent-size="parentSize"
            />
          </div>
        </div>
      </transition>
      <transition name="wrapper-fade">
        <div class="fake-overlay" v-if="showMap" @click.self="showMap = false">
          <div style="width: 50vw; height: 500px;">
            <Map
              style="width: 50vw; height: 500px;"
              :from="from"
              :to="to"
              :route="mapRoute"
            />
          </div>
        </div>
      </transition>
      <transition name="wrapper-fade">
        <div
          class="fake-overlay"
          v-if="showDownloadDialog"
          @click.self="showDownloadDialog = false"
          style="align-items: start !important; padding-top:4vw"
        >
          <DownloadManager
            :token="token"
            :cdCliente="clientId"
            :parent-size="parentSize"
            @onCloseDownloadModal="onCloseDownloadModal"
          />
        </div>
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import { locales } from "./const/locales";
import "./helpers/injectScripts";

import fetcher from "./helpers/fetcher";
import RoutesForm from "./components/RoutesForm";
import DownloadManager from "./components/DownloadManager";
import Map from "./components/Map";
import vClickOutside from "v-click-outside";

export default {
  vuetify,
  i18n,
  name: "RoutesConfig",

  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    RoutesForm,
    DownloadManager,
    Map
  },

  props: {
    token: {
      type: String,
      default: () =>
        "eyJhbGciOiJIUzUxMiIsInppcCI6IkRFRiJ9.eNokjcEKwjAQRH9F9lwllVbbHouehXrQm4Q2xoV0E5I0CuK_u9HjzJt9-wb7JOWhg1F5Lyekh71NKkEBihJ6S7OiyLgfTpfzceB-IeSiFgUghShpVIyDuZeCYRit-2XtJEeSc04Hu2gjw-oqE_KzAowkvUidmYvrPmsj5mmzqwSbjdVIDKf_4SagSdmnXg66sm6rdts2Yv_5AgAA__8.YfDh9TeD-h0ER0PGVYVTeg8NnzmuPrl-RyBqUJ5gf4jaofbDosPN29Ecfc7Rh541NojqCnyoJXpcKgyldiJPnA"
    },
    locale: {
      type: String,
      default: () => "pt-br"
    },
    cdCliente: {
      type: Number,
      default: () => 65
    }
  },

  data() {
    return {
      headers: [],
      expanded: [],
      routes: [],
      showDialog: false,
      showMap: false,
      from: [0, 0],
      to: [0, 0],
      mapRoute: [],
      parentSize: 0,
      showDownloadDialog: false
    };
  },

  computed: {
    bcpLocale() {
      let l = this.locale;
      let bcp = "";
      switch (l) {
        case "en-us":
          bcp = "en-US";
          break;
        case "es-es":
          bcp = "es-ES_tradnl";
          break;
        case "pt-br":
          bcp = "pt-BR";
          break;
        case "ru-ru":
          bcp = "ru-RU";
          break;
        case "uk-ua":
          bcp = "uk-UA";
          break;
        default:
          bcp = "en-US";
          break;
      }
      return bcp;
    },
    allCurrentUsedFarms() {
      return this.routes.map(r => r.fazenda_id);
    },
    clientId() {
      if (this.cdCliente == 100005) return 7;
      if (this.cdCliente == 100004) return 65;
      if (this.cdCliente == 100003) return 35;
      if (this.cdCliente == 100001) return 54;
      return this.cdCliente;
    }
  },

  methods: {
    updateTableData(v) {
      this.routes.push(v);
      this.showDialog = false;
    },

    toggleDownloadDialog() {
      this.showDownloadDialog = !this.showDownloadDialog;
    },

    updateRowData(v) {
      let index = this.routes.map(route => route.id).indexOf(v.id);
      Object.assign(this.routes[index], v);
      this.expanded = [];
    },

    deleteRoute(id) {
      if (!confirm(this.$i18n.t("deleteDialog.message"))) return false;

      fetcher.delete("/paths/" + id, this.token);

      let index = this.routes.map(route => route.id).indexOf(id);
      this.routes.splice(index, 1);
    },

    async fetchTableData() {
      await fetcher
        .get("/paths/full", this.token)
        .then(res => (this.routes = res));
    },
    toggleMap(value) {
      this.showMap = true;
      this.from = value.from;
      this.to = value.to;
      this.mapRoute = value.mapRoute;
    },
    updateSize() {
      this.parentSize = this.$el.clientWidth;
    },
    onCloseDownloadModal() {
      this.showDownloadDialog = false;
    }
  },

  mounted() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize);
    this.fetchTableData();
    this.headers = [
      { text: this.$i18n.t("table.description"), value: "name" },
      { text: this.$i18n.t("table.from_route"), value: "from_route.name" },
      { text: this.$i18n.t("table.to_route"), value: "to_route.name" },
      { text: this.$i18n.t("table.remove"), value: "delete" },
      { text: this.$i18n.t("table.edit"), value: "data-table-expand" }
    ];
  },

  updated() {
    this.$nextTick(function() {
      this.updateSize();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateSize);
  },

  watch: {
    locale: {
      handler: function(value) {
        // Antes de setar o currentLocale, checar se existe na lista de locales
        if (locales.indexOf(value) > -1) {
          this.$i18n.locale = value;
        } else {
          this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
        }
      },
      immediate: true
    }
  }
};
</script>
<style>
* {
  box-sizing: border-box;
}
.v-data-table {
  font-size: 1.5em !important;
}
.content {
  min-height: calc(100vh - 49px);
  position: relative;
}
.right-aligned {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.fake-overlay {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.dialog {
  max-width: 900px;
}
.wrapper-fade-enter-active,
.wrapper-fade-leave-active {
  transition: opacity 0.3s;
}
.wrapper-fade-enter,
.wrapper-fade-leave-to {
  opacity: 0;
}
.pointer {
  cursor: pointer;
}
.floating-modal {
  position: absolute;
  z-index: 5;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css");
</style>
