import axios from "axios";

export default {
  async get(path, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token
      }
    };
    await axios
      .get(process.env.VUE_APP_API_ROUTES + path, config)
      .then(res => (response = res))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });
    return response.data;
  },

  async post(path, data, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token
      }
    };

    await axios
      .post(process.env.VUE_APP_API_ROUTES + path, data, config)
      .then(res => (response = res))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  },

  async put(path, data, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token
      }
    };

    await axios
      .put(process.env.VUE_APP_API_ROUTES + path, data, config)
      .then(res => (response = res))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  },

  async delete(path, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token
      }
    };

    await axios
      .delete(process.env.VUE_APP_API_ROUTES + path, config)
      .then(res => (response = res))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  },

  async getItems(path, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token,
        "request-origin": "routes-wc",
        "Content-Type": "application/json"
      }
    };

    await axios
      .get(process.env.VUE_APP_API_CRUD + path, config)
      .then(res => (response = res.data))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  },

  async postItems(path, data, token) {
    let response;
    let config = {
      headers: {
        "x-auth-token": token,
        "request-origin": "routes-wc",
        "Content-Type": "application/json"
      }
    };
    

    await axios
      .post(process.env.VUE_APP_API_CRUD + path, data, config)
      .then(res => (response = res.data))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  },

  async download(path, token) {
    let response;

    let config = {
      headers: {
        "x-auth-token": token
      },
      responseType: "blob"
    };

    await axios
      .get(process.env.VUE_APP_API_ROUTES + path, config)
      .then(res => (response = res))
      .catch(err => {
        throw new Error("erro ao realizar requisição", err);
      });

    return response;
  }
};
